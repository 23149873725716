<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td class="truncate">
                {{ item.key }}
            </td>
            <td
                class="truncate"
                v-for="(lang, index) in getLangRange"
                :key="index"
            >
                {{ getTranslation(item, lang) }}
            </td>
            <td class="d-flex align-center justify-end">
                <v-menu bottom v-if="remove()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === REMOVE === === -->
                        <v-list-item @click="openConfirmModal(item.key)">
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn small @click="openModal(item)" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import i18next from 'i18next'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmContent from '../../ConfirmModal/ConfirmContent.vue'

export default {
    props: ['items'],
    components: {
        ConfirmContent,
    },
    computed: {
        ...mapGetters([
            'getLangRange',
            'getNameSpace',
            'getTranslationValues',
            'getTranslationsTable',
        ]),
    },
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    methods: {
        ...mapActions(['deleteTranslation']),
        ...mapMutations([
            'setTranslationKey',
            'setTranslationValues',
            'clearTranslationValues',
        ]),
        openModal(item) {
            this.clearTranslationValues()
            this.setTranslationKey({ key: item.key })
            this.setTranslationValues()
            this.$emit('editOpenModal')
        },
        getTranslation(item, lang) {
            return i18next.getResource(lang, this.getNameSpace, item.key) || ''
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async deleteItem() {
            let result = await this.deleteTranslation({
                key: this.removeItemID,
            })
            if (result) this.openConfirm = false
        },
    },
}
</script>
