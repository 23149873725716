<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <AdminHeader
                    :registryTitle="
                        $t('global:translations') +
                        ' - ' +
                        $route.params.namespace
                    "
                    :registryUrl="'translations'"
                    @clearSearch="clearTable"
                />
                <Table
                    :headers="headers"
                    :length="getTranslationsTable.pages"
                    :page="getTranslationsTable.page"
                    :defaultFields="getTranslationsFields.searchFields"
                    @search="setSearch"
                    @change-page="setPage"
                    @sort="setSort"
                >
                    <Columns
                        slot="columns"
                        :items="getTranslationsTable.items"
                        @editOpenModal="editOpenModal"
                    />
                </Table>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                @click="openModal"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-briefcase-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('translation:createKey') }}</span>
                    </v-tooltip>
                </div>
                <Modal
                    :title="
                        type == 'create'
                            ? $t('translation:addingTranslation')
                            : $t('translation:editTranslation')
                    "
                    :open="open"
                    :height="'250'"
                    v-on:close="closeModal"
                >
                    <Content slot="AEContent" :type="type" />
                    <Buttons slot="buttons" @closeModal="closeModal" />
                </Modal>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Header from './../../../components/Admin/Header/Header'
import Columns from './../../../components/Admin/Translations/Details/Table/Columns'
import Buttons from './../../../components/Admin/Translations/Details/Modal/Buttons.vue'
import Content from './../../../components/Admin/Translations/Details/Modal/Content.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Header,
        Columns,
        Buttons,
        Content,
    },
    methods: {
        ...mapActions([]),
        ...mapMutations([
            'setTranslationsTable',
            'clearTranslationsTable',
            'clearTranslationValues',
            'setTranslationValues',
            'clearTranslationErrors',
        ]),
        setSearch(search) {
            this.setTranslationsTable({ search, page: 1 })
        },
        setPage(page) {
            this.setTranslationsTable({ page })
        },
        closeModal() {
            this.open = false
        },
        editOpenModal() {
            this.clearTranslationErrors()
            this.type = 'edit'
            this.open = true
        },
        openModal() {
            this.type = 'create'
            this.clearTranslationErrors()
            this.clearTranslationValues()
            this.setTranslationValues()
            this.open = true
        },
        clearTable() {
            this.clearTranslationsTable()
            this.setTranslationsTable()
        },
        setSort(sort) {
            this.setTranslationsTable({ sort })
        },
    },
    computed: {
        ...mapGetters([
            'getTranslationsTable',
            'getTranslationsFields',
            'getLangRange',
        ]),
    },
    beforeMount() {
        this.setTranslationsTable({ namespace: this.$route.params.namespace })
        this.getLangRange.forEach(el =>
            this.headers.splice(this.headers.length - 1, 0, {
                text: el,
                value: el,
                width: 'auto',
                sortable: false,
            })
        )
    },
    data() {
        return {
            open: false,
            action: '',
            type: 'create',
            headers: [
                {
                    text: this.$t('translation:key'),
                    value: 'key',
                    width: 'auto',
                    sortable: true,
                },
                {
                    value: 'action',
                    width: '8%',
                    text: '',
                    sortable: true,
                },
            ],
        }
    },
}
</script>
