<template>
    <div>
        <v-btn
            @click="save()"
            :disabled="disabled"
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },
    computed: {
        ...mapGetters(['getTranslationErrors']),
    },
    methods: {
        ...mapActions(['createTranslation']),
        ...mapMutations(['setTranslationErrors','clearTranslationErrors']),
        async save() {
            this.clearTranslationErrors();
            this.setTranslationErrors();
            if (this.getTranslationErrors.stepOne.validation) {
                this.disabled = true
                let success = await this.createTranslation()
                if (success) this.$emit('closeModal')
                this.disabled = false
            }
        },
    },
}
</script>
