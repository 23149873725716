<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                data-cy="stepper"
                                :rules="[
                                    () =>
                                        getTranslationErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('translation:informations')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                type=='create'?$t('translation:addingTranslation'):$t('translation:editTranslation')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="
                                                getTranslationValues['key']
                                            "
                                            :label="$t('translation:key')"
                                            :error-messages="
                                                getTranslationErrors.stepOne.key
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        v-for="(item, index) in getLangRange"
                                        :key="index"
                                    >
                                        <v-text-field
                                            v-model="getTranslationValues[item]"
                                            :label="item"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    props:['type'],
    data() {
        return {
            step: 1,
        }
    },
    computed: {
        ...mapGetters([
            'getLangRange',
            'getTranslationValues',
            'getTranslationErrors',
        ]),
    },
    methods: {
        ...mapMutations(['setTranslationValues']),
    },
}
</script>
